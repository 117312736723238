import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import tabIcon from '../../assets/images/tabIcon.svg'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import { BASE_URL } from '../../constants';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import CustomInput from '../../components/common/CustomInput';
import CustomMultiSelect from '../../components/common/CustomMultiSelect';

// a little function to help us with reordering the result
// const reorder = (list, startIndex, endIndex) => {
//     const result = Array.from(list);
//     const [removed] = result.splice(startIndex, 1);
//     result.splice(endIndex, 0, removed);

//     return result;
// };

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    //userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "" : "",
    border: isDragging ? "none" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});


export default function DraggableList(props) {

    const {
        category = [],
        handleChangeSubCategory,
        handleDeleteCategory,
        values,
        onUpdateCategoryOrder,
        handleEditCategory
    } = props;

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            props.category,
            result.source.index,
            result.destination.index
        );
        onUpdateCategoryOrder(items)
    }

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

    return (
        // <DragDropContext onDragEnd={onDragEnd}>
        //     <Droppable droppableId="droppable">
        //         {(provided, snapshot) => (
        //             <ul
        //                 className="category-ul-list"
        //                 {...provided.droppableProps}
        //                 ref={provided.innerRef}
        //             >
        //                 {category && category.map((item, index) => {
        //                     let imgUrl = (item && item.image) || ''
        //                     return <Draggable key={item._id} draggableId={item._id} index={index}>
        //                         {(provided, snapshot) => (

        //                             <li className="custon-list"
        //                                 ref={provided.innerRef}
        //                                 {...provided.draggableProps}
        //                                 {...provided.dragHandleProps}
        //                                 style={getItemStyle(
        //                                     snapshot.isDragging,
        //                                     provided.draggableProps.style
        //                                 )}
        //                             >
        //                                 <MenuIcon className="mr-2" />
        //                                 <div className="category-img-sec">
        //                                     {
        //                                         imgUrl ? <img src={`${BASE_URL}${imgUrl}`} className="category-img" alt="" />
        //                                             :
        //                                             <CropOriginalIcon />
        //                                     }

        //                                 </div>
        //                                 <div className="left-section">
        //                                     <CustomInput
        //                                         name="categoryName"
        //                                         fullWidth
        //                                         value={item.categoryName || ""}
        //                                         // onChange={(e) => { handleChangeCategory(e, index) }}
        //                                         onChange={() => { }}
        //                                         label="Category Name"
        //                                     />
        //                                 </div>
        //                                 <CustomMultiSelect
        //                                     label="Sub Category"
        //                                     name={"name"}
        //                                     fullWidth
        //                                     options={values || []}
        //                                     handleChange={(e, val) => { handleChangeSubCategory(e, val, index) }}
        //                                     value={item.blazeCategories || []}
        //                                     className={"w-50"}
        //                                 />
        //                                 <EditIcon className="material-ui-icons ml-1" onClick={() => { handleEditCategory(item) }} />
        //                                 <DeleteOutlineIcon className="material-ui-icons ml-1" onClick={() => { handleDeleteCategory(item._id) }} />
        //                             </li>
        //                         )}
        //                     </Draggable>
        //                 })}
        //                 {provided.placeholder}
        //             </ul>
        //         )}
        //     </Droppable>
        // </DragDropContext>
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul
              className="category-ul-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {category &&
                category.map((item, index) => {
                  let imgUrl = item && item.image || '';

                  return (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li
                          className="custon-list"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <MenuIcon className="mr-2" />
                          <div className="category-img-sec">
                            {imgUrl ? (
                              <img
                                src={`${BASE_URL}${imgUrl}`}
                                className="category-img"
                                alt=""
                              />
                            ) : (
                              <CropOriginalIcon />
                            )}
                          </div>
                          <div className="left-section">
                            <CustomInput
                              name="categoryName"
                              fullWidth
                              value={item.categoryName || ''}
                              // onChange={(e) => { handleChangeCategory(e, index) }}
                              onChange={() => {}}
                              label="Category Name"
                            />
                          </div>

                          <CustomInput
                            name="subCategoryName"
                            id={item._id} 
                            fullWidth
                            value={item.blazeCategories.length ? item.blazeCategories.map((blaze=>blaze?.name))?.join(',') : ''}
                            onChange={(e) => handleChangeSubCategory(e, null, index)}
                            label="Sub Category"
                            className="w-50"
                          />  
                            {/* <CustomMultiSelect
                              label="Sub Category"
                              name="name"
                              fullWidth
                              options={values || []}
                              handleChange={(e, val) =>
                                handleChangeSubCategory(e, val, index)
                              }
                              value={item.blazeCategories || []}
                              className="w-50"
                            /> */}
                          <EditIcon
                            className="material-ui-icons ml-1"
                            onClick={() => {
                              handleEditCategory(item);
                            }}
                          />
                          <DeleteOutlineIcon
                            className="material-ui-icons ml-1"
                            onClick={() => {
                              handleDeleteCategory(item._id);
                            }}
                          />
                        </li>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
}
