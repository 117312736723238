import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import GroupIcon from '@material-ui/icons/Group';
import ViewListIcon from '@material-ui/icons/ViewList';
import { useDispatch } from 'react-redux';
import * as authActions from "../../../redux/actions/auth"
import { useHistory } from 'react-router-dom';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { getData, isSuperAdminAccess } from '../../../utils';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ScheduleIcon from '@material-ui/icons/Schedule';

const drawerWidth = 270;

let initialSideBarContent = [
    { title: "Manage Products", url: "/manage_product", alias: "manage_product", icon: <ViewCarouselIcon /> },
    { title: "Manage Categories", url: "/manage_category", alias: "manage_category", icon: <DashboardIcon /> },
    // { title: "Service Areas", url: "/service_areas", alias: "service_areas", icon: <PersonPinCircleIcon /> },
    { title: "Promotions", url: "/promotions", alias: "promotions", icon: <LocalOfferIcon /> },
    { title: "Orders", url: "/orders", alias: "orders", icon: <ShoppingCartIcon /> },
    { title: "Brands", url: "/brands", alias: "brands", icon: <ViewListIcon /> },
    // { title: "Terminals", url: "/terminals", alias: "terminals", icon: <LocalShippingIcon /> },
    { title: "Manage Delivery Hours", url: "/delivery_hours", alias: "delivery_hours", icon: <ScheduleIcon /> },
    // { title: "Notifications", url: "/notification", alias: "notification", icon: <GroupIcon /> },
    { title: "Users", url: "/users", alias: "users", icon: <GroupIcon /> },
]

function CustomDrawer(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();


    const [sideBarContent, setSideBarContent] = useState([...initialSideBarContent])

    const { children } = props

    let pageTitleArr = history.location.pathname && history.location.pathname.replace("_", " ").split("/") || null;
    let temp = pageTitleArr.length > 2 ? pageTitleArr.splice(2) : pageTitleArr
    let pageTitle = pageTitleArr

    useEffect(() => {
        if (!isSuperAdminAccess()) {
            let updatedSideBarContent = [...sideBarContent]
            updatedSideBarContent = updatedSideBarContent.filter(item => item.alias !== "users")
            setSideBarContent(updatedSideBarContent)
        }
    }, [])


    const [open, setOpen] = React.useState(true);


    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logout = () => {
        dispatch(authActions.logoutUser())
        history.push("/login")
    };

    const getAdminEmail = () => {
        const loginData = JSON.parse(getData('pineappleExpress-admin'))
        if (loginData) {
            return loginData.email
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <span className="text-top"> {pageTitle || "Cannabis Dashboard"} </span>
                    </Typography>
                    <h4 className="appbar-loggedin-email">{getAdminEmail()}</h4>
                    <ExitToAppIcon className="appbar-logout-btn" onClick={() => { logout() }} />
                    {/* <button onClick={handleDrawerOpen}>hello</button> */}
                </Toolbar>

            </AppBar>


            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={`${classes.toolbarIcon} sidebar-left-top-sec`}>
                    <div className="sidebar-left-top-logo">
                        <img src="/images/logo.png" alt="logo" />
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>


                <List className="main-nav">
                    {
                        sideBarContent.map((data, index) => {
                            let isSelected = history.location.pathname.includes(data.alias)
                            return (
                                <ListItem
                                    button
                                    key={index}
                                    onClick={() => { history.push(data.url) }}
                                    className={`menu-items-list-view ${isSelected ? "active-drawer" : ""}`}
                                >

                                    <ListItemIcon>
                                        {data.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={`${data.title}`} />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Drawer>


            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {children}
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px0 0',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: '#ffffff',
        boxShadow: '0px 0px 0px -1px rgb(0 0 0 / 4%), 0px 4px 0px 0px rgb(0 0 0 / 0%), 0px 1px 4px 0px rgb(0 0 0 / 24%)'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: 'grey'
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        color: "#d4a841"
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default CustomDrawer