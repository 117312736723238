import React from 'react';
import { Grid, FormGroup, Button, Typography, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import CustomInput from '../../components/common/CustomInput';
import { getProductUnitByCategoryId } from '../../utils';


function EditProductDetails(props) {

    const { infoAndEffectsData, singleProductDetailData, data, onChangeCheckbox, handleSubmit, onChangeBrandDescription, productStatus, onChangeProductStatus, onChangeBestSellingCheckBox } = props

    const {
        name,
        category,
        brand,
        flowerType,
        customWeight,
        thc,
        cbd,
        cbn,
        unitPrice,
        potencyAmount,
        description,
        priceBreaks,
        adminProductName = '',
        bmwCategoryName = ''
    } = singleProductDetailData

    let infoEffectObj = infoAndEffectsData && infoAndEffectsData.length && infoAndEffectsData.filter((v) => v.strainType == (flowerType && flowerType.toLowerCase()))[0]

    let infoEffectArr = (infoEffectObj && infoEffectObj.infoEffects) || []
    let salesPrice = (priceBreaks && priceBreaks.length && priceBreaks[0] && priceBreaks[0].salePrice) || null

    const unitTypeByCategoryName = getProductUnitByCategoryId(bmwCategoryName, { thc, cbd, cbn }, potencyAmount)

    return (
        <Grid container>
            <Grid item xs={12} className="product-details-listing txt-left">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h4">Edit Menu Item</Typography>
                    </Grid>
                    <Grid item xs={6}>

                        <Typography className="text-right">

                            <strong> Status :</strong>
                            <Switch
                                checked={productStatus}
                                onChange={onChangeProductStatus}
                                color="primary"
                                name="active"
                            />
                            <p style={{ margin: 0 }}>
                                {productStatus ? <span>This item is <span style={{ color: "green", fontWeight: 600 }}>ACTIVE</span> and will be shown on your menu.</span> : <span>This item is <span style={{ color: "red", fontWeight: 600 }}>INACTIVE</span> and will not be shown on your menu.</span>}
                            </p>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Category :</strong> <span> {bmwCategoryName || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Sub-category :</strong> <span> {(category && category.name) || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={12} className="py-2">
                        <Typography ><strong> Admin Product Name :</strong> <span> {adminProductName || "-"}</span></Typography>
                    </Grid>

                    <Grid item xs={12} className="py-2">
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Typography ><strong>Website Product Name :</strong></Typography>
                            </Grid>
                            <Grid item xs={12} md={12} className="pt-2">
                                <CustomInput
                                    label="Website Product Name"
                                    placeholder="Enter Website Product Name"
                                    name="websiteProductName"
                                    value={(data && data.websiteProductName) || ""}
                                    fullWidth
                                    onChange={(e) => { onChangeBrandDescription(e) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Brand :</strong> <span> {(brand && brand.name) || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Type :</strong> <span> {flowerType || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> THC Content :</strong> <span> {unitTypeByCategoryName.thc || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> CBD Content :</strong> <span> {unitTypeByCategoryName.cbd || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> CBN Content :</strong> <span> {unitTypeByCategoryName.cbn || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={6} className="py-2">
                        <Typography ><strong> Price :</strong> <span> ${parseFloat(unitPrice).toFixed(2) || "-"}</span></Typography>
                    </Grid>

                    {
                        salesPrice ?
                            <Grid item xs={12} className="py-2">
                                <Typography ><strong> Sale Price :</strong> <span> ${parseFloat(salesPrice).toFixed(2) || "-"}</span></Typography>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} className="py-2">
                        <Typography ><strong> Description :</strong> <span> {description || "-"}</span></Typography>
                    </Grid>
                    <Grid item xs={12} className="py-2">
                        <Typography ><strong> Brand Description :</strong> <span> {(data && data.brandDescription) || ""}</span></Typography>
                    </Grid>
                    {/* <Grid item xs={12} className="py-2">
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Typography ><strong> Brand Description : </strong></Typography>
                            </Grid>
                            <Grid item xs={12} md={12} className="pt-2">
                                <CustomInput
                                    label="Brand description"
                                    placeholder="Enter description here.."
                                    name="brandDescription"
                                    value={ }
                                    fullWidth
                                    onChange={(e) => { onChangeBrandDescription(e) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid> */}

                    <Grid item xs={12} md={12} className="pt-2">
                        <Typography ><strong> Best Selling : </strong></Typography>
                        <FormGroup className="effects-listing">
                            <div className="effect-chkbox">
                                <CustomCheckbox
                                    label="Add Product to Best-Selling"
                                    onChange={onChangeBestSellingCheckBox}
                                    value={data?.isBestSelling}
                                />
                            </div>
                        </FormGroup>
                    </Grid>
                    {
                        infoEffectArr && infoEffectArr.length ?
                            <Grid item xs={12} className="pt-2">
                                <Grid container className="effects-sec">
                                    <Grid item xs={12} md={12}>
                                        <Typography ><strong> Effects : </strong></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} className="pt-2">
                                        <FormGroup className="effects-listing">
                                            {
                                                infoEffectArr && infoEffectArr.length ? infoEffectArr.map((item, index) => {
                                                    const { title } = item
                                                    let value = data && data.infoEffects && data.infoEffects.includes(title)
                                                    return <div key={index} className="effect-chkbox">
                                                        <CustomCheckbox
                                                            label={title}
                                                            onChange={(e) => onChangeCheckbox(e, title)}
                                                            value={value || false}
                                                        />
                                                    </div>
                                                })
                                                    :
                                                    null
                                            }
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            null
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div className="product-save-btn">
                    <Button
                        onClick={(e) => { handleSubmit(e) }}
                        variant="contained"
                        color="primary">
                        Save
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default EditProductDetails