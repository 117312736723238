import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import CustomInput from '../../components/common/CustomInput';
import * as productsAction from "../../redux/actions/products"
import * as commonAction from "../../redux/actions/common"
import { useDispatch, useSelector } from 'react-redux';
// import CustomDropDown from '../../components/common/CustomDropDown';
import { BASE_URL } from '../../constants';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


function AddCategory(props) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { categoryImages } = useSelector(state => state.products)
    const { title = "Add", data: editData = {} } = modalData

    const [category, setCategory] = useState({ categoryName: "", metaTitle: "", metaDescription: "" })
    const [selectedImage, setSelectedImage] = useState('')


    useEffect(() => {
        if (editData) {
            setCategory({
                categoryName: editData.categoryName || '',
                metaTitle: editData.metaTitle || '',
                metaDescription: editData.metaDescription || ''
            })
            setSelectedImage(editData.image || '')
        }
    }, [])

    const handleChangeCategory = (e) => {
        const { name, value } = e.target

        setCategory({ ...category, [name]: value })
    }

    const addCategory = () => {
        let updatedData = { ...category }
        if (selectedImage) {
            updatedData = {
                ...updatedData,
                image: selectedImage
            }
        }
        dispatch(productsAction.addCategory(updatedData, ((editData && editData._id) || ""))).then(() => {
            dispatch(productsAction.getAllCategory())
            dispatch(commonAction.closeCustomModalDialog())
        }).catch(() => { })
    }

    const onClickSelectImage = (url) => {
        setSelectedImage(url)
    }

    return (
        <Grid container component="main" className={`${classes.root} add-category-modal-container`}>

            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Category</Typography>
            </Grid>
            <Grid item xs={12}>
                <h4 className="cus-label">Please Select Image</h4>
            </Grid>
            <Grid item xs={4} className="add-category-input">
                <Grid container>
                    <Grid item xs={12} className="category-image-item-2">
                        <div className="select-category-image selected">
                            <img src={BASE_URL.concat(selectedImage)} alt="" />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} className="add-category-input">
                <Grid container>
                    <Grid item xs={12} className="category-images-container">
                        <Grid container>
                            <Grid item xs={12} className="scrollable-sec">
                                <Grid container>
                                    {
                                        categoryImages.map(image => {
                                            const isSelected = image.url === selectedImage
                                            return <Grid item xs={3} className="inner-select-image-item">
                                                {isSelected ? <div className="tick-placement"><CheckCircleIcon /></div> : null}
                                                <div className={`select-category-image ${isSelected ? 'disable-select' : ''}`} onClick={() => onClickSelectImage(image.url)}>
                                                    <img src={BASE_URL.concat(image.url)} alt="" />
                                                </div>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <CustomInput
                            name="categoryName"
                            value={category.categoryName || ""}
                            onChange={(e) => { handleChangeCategory(e) }}
                            label="Category Name"
                            className="w-100"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            name="metaTitle"
                            value={category.metaTitle || ""}
                            onChange={(e) => { handleChangeCategory(e) }}
                            label="Meta Title"
                            className="w-100"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            name="metaDescription"
                            value={category.metaDescription || ""}
                            onChange={(e) => { handleChangeCategory(e) }}
                            label="Meta Description"
                            className="w-100"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} className="jc-ctr add-button">
                        <Button onClick={() => { addCategory() }} variant="contained" color="primary" fullWidth>Submit</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddCategory