import React, { useEffect, useState } from 'react'
import * as actions from "../../redux/actions/products"
import * as commonActions from "../../redux/actions/common"
import { Grid, Button, CircularProgress, TableCell, Box } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import CustomTable from '../../components/common/CustomTable'
import CustomInput from '../../components/common/CustomInput'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

export default function Brands() {

    const dispatch = useDispatch()
    const { allBmwBrands = {} } = useSelector(state => state.products)
    const { values: bmwBrands = [] } = allBmwBrands || {}

    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        dispatch(actions.getAllBrandsByProducts())
        dispatch(actions.getAllBmwBrands())
    }, [])

    const onEditBrand = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddBrandModal"))
    }

    const onDeleteBrand = (id) => {
        dispatch(actions.deleteBrandFromBmw(id)).then(() => {
            dispatch(actions.getAllBmwBrands())
        })
    }
    let filtersBmwBrands = bmwBrands
    if (searchTerm) {
        filtersBmwBrands = bmwBrands.filter(item => String(item.brandName).toLowerCase().includes(searchTerm.toLowerCase()))
    }

    return (
        <Grid container className="promotions-container">
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <div style={{}}>
                        <CustomInput
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            className="w-200 pt-1"
                            placeholder="Search by Brand name"
                            size="small"
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddBrandModal")) }}
                        className=""
                    >
                        Add Brand
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <CustomTable
                    tableHeading={["S.no", "Brand Name", "Description", "Actions"]}
                    rowsData={createZipCodeTableCell(filtersBmwBrands, onEditBrand, onDeleteBrand)}
                />
            </Grid>

        </Grid>
    )
}


function createZipCodeTableCell(data = [], onEditBrand = () => { }, onDeleteBrand = () => { }) {

    return data && data && data.length ?
        data.map((item, index) => {
            const { brandId, brandName = '', description = '' } = item
            return <React.Fragment key={brandId}>
                <TableCell >{index + 1}</TableCell>
                <TableCell >{brandName}</TableCell>
                <TableCell style={{ maxWidth: "600px" }}>{description}</TableCell>
                <TableCell>
                    <EditIcon onClick={() => onEditBrand(item)} />
                    <DeleteOutlineIcon onClick={() => onDeleteBrand(brandId)} />
                </TableCell>
            </React.Fragment>
        }) : []
}