import React, { useEffect } from 'react'
import { Button, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommonLoading from '../../components/common/CommonLoading';
import AllDelivery from './AllDelivery';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryHours } from '../../redux/actions/deliveryHours';
import * as commonActions from "../../redux/actions/common"
import * as actions from "../../redux/actions/deliveryHours"


const useStyles = makeStyles((theme) => ({
    root: {
        // height: '100vh',
    },

}));

export default function DeliveryHours() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { allDeliveryHourData = {}, isLoading } = useSelector(state => state.deliveryHours)
    const { values: deliveryHours = [] } = allDeliveryHourData

    useEffect(() => {
        dispatch(getDeliveryHours()).then(() => { }).catch(() => { })
    }, [])

    const handleEdit = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddDeliveryHoursModal"))
    }

    const handleDelete = (item) => {
        actions.deleteDeliverHours(item._id).then(() => {
            dispatch(actions.getDeliveryHours())
        }).catch(() => { })
    }

    return (
        <Grid container component="main" className={`${classes.root} trucks-container`}>
            <Grid item xs={12} className="top-btn-sec">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddDeliveryHoursModal")) }}
                    className=""
                >
                    {false ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Add New Location'}
                </Button>
            </Grid>

            <Grid item xs={12} className="">
                <AllDelivery
                    deliveryHours={deliveryHours}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    isEdit={true}
                />
            </Grid>

            <CommonLoading isLoading={isLoading} />
        </Grid>
    );
}
