import { toast } from 'react-toastify';
import { getDeliveryHoursAPI, createNewDeliveryHourAPI, updateDeliverHoursAPI, deleteDeliverHoursAPI } from "../api/deliveryHours";

export const GET_DELIVERY_HOURS = "GET_DELIVERY_HOURS";
export const GET_DELIVERY_HOURS_SUCCESSFULL = "GET_DELIVERY_HOURS_SUCCESSFULL";
export const GET_DELIVERY_HOURS_FAILED = "GET_DELIVERY_HOURS_FAILED";

export function getDeliveryHours() {
    return dispatch => {
        dispatch({
            type: GET_DELIVERY_HOURS
        })
        return new Promise((resolve, reject) => {
            getDeliveryHoursAPI()
                .then((res) => {
                    dispatch({
                        type: GET_DELIVERY_HOURS_SUCCESSFULL,
                        data: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_DELIVERY_HOURS_FAILED
                    })
                    return reject(err)
                })
        })
    }
}


export function postDeliveryHours(data) {
    return new Promise((resolve, reject) => {
        createNewDeliveryHourAPI(data)
            .then((res) => {
                return resolve(res)
            })
            .catch((err => {
                toast.error(err)
                return reject(err)
            }))

    })
}


export function updateDeliveryHours(id, data) {
    return new Promise((resolve, reject) => {
        updateDeliverHoursAPI(id, data)
            .then(res => {
                return resolve(res)
            })
            .catch((err => {
                toast.error(err)
                return reject(err)
            }))
    })
}


export function deleteDeliverHours(id) {
    return new Promise((resolve, reject) => {
        deleteDeliverHoursAPI(id)
            .then(res => {
                return resolve(res)
            })
            .catch((err => {
                return reject(err)
            }))
    })
}